import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import { InertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
//import swal from 'vue-sweetalert2';
import swal from "sweetalert2/dist/sweetalert2";
window.swal = swal;
import 'sweetalert2/dist/sweetalert2.min.css';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'
import Notifications from 'vue-notification'
import VueLodash from 'vue-lodash'
import lodash, { cond } from 'lodash'

import pluralize from 'pluralize'
import VueCookies from 'vue-cookies'

window.eventBus = new Vue();


Vue.config.productionTip = false
Vue.mixin({ methods: { route: window.route } })
Vue.use(InertiaApp)
Vue.use(PortalVue)
Vue.use(VueMeta)
Vue.use(require('vue-moment'))
Vue.use(Viewer);
Vue.component('datetime', Datetime);
Vue.use(Notifications)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueCookies, { expires: '7d'})

window.Common = require('./Custom/Common')

import Vueform from '@vueform/vueform'
import vueformConfig from './../../vueform.config'
Vue.use(Vueform, vueformConfig)

InertiaProgress.init()

let app = document.getElementById('app')

/*@ Component will be used to dynamic the colors in Layout.vue */
Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});

Vue.filter('formatDate', function (value) {
  if (!value) return ''

  return moment(value).format('MM/DD/YYYY')
})

Vue.filter('formatDateAndTime', function (value) {
  if (!value) return ''

  return moment(value).format('MM/DD/YYYY hh:mm a')
})

Vue.filter('unixDateAndTime', function (value) {
  if (!value) return ''

  return moment.unix(value).format('MM/DD/YYYY hh:mm a')
})

Vue.filter('unixDate', function (value) {
  if (!value) return ''

  return moment.unix(value).format('MM/DD/YYYY')
})

Vue.filter('currency', function (value) {
  if (!value) {
    value = 0
  }
  if (typeof value !== "number") {
    value = parseFloat(value)
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(value);
});


Vue.filter('phone', function (value) {
  if (!value) return ''
  return value.replace(/(0)(\d{5})(\d{5}|\d{6})/, '($1)-$2-$3')
})

Vue.filter('trimString', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
})

import axios from "axios";
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};

import NProgress from "nprogress";

Vue.mixin({
  methods: {
    strToColor(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    toSingle(value) {
      return pluralize.singular(value)
    },
    toPlural(value) {
      return pluralize.plural(value)
    },
    capitalizeFirstLetter (string) {
      if (!string) {
        return
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showMessageInPopup(msg) {
      window.Common.default
      .alertPopup({
        text: msg,
        confirmButton: "OK",
        showCancel: false,
        type: "error",
        iconHtml: '<img src="/images/cancel.svg">',
      })
    },
    showSuccessMessageInPopup(msg) {
      return window.Common.default
        .alertPopup({
          text: msg,
          confirmButton: "OK",
          showCancel: false,
          type: "success",
        })
    },
  }
})

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - Capnorthwest` : 'Capnorthwest'
  },
  render: h => h(InertiaApp, {
    props: {
      initialPage: JSON.parse(app.dataset.page),
      resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
    },
  }),
}).$mount(app)
