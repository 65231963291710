let functions = {
    alertPopup(obj) {
        let icon = 'warning'

        if (obj.type) {
            icon = obj.type
        }

        if (obj.type === 'none') {
            icon = null
        }

        if (obj.iconHtml) {
            icon = null
        }

        return swal.fire({
            title: obj.title,
            text: obj.text,
            toast: obj.toast ? obj.toast : false,
            position: obj.position ? obj.position : 'center',
            html: obj.html ? obj.html : false,
            customClass: obj.customClass ? obj.customClass : '',
            icon: icon,
            iconHtml: obj.iconHtml ? obj.iconHtml : null,
            timer: obj.timer ? obj.timer : false,
            timerProgressBar: obj.timerProgress ? obj.timerProgress : false,
            showCancelButton: (obj.showCancel || obj.showCancel === false) ? obj.showCancel : true,
            showConfirmButton: (obj.showConfirm || obj.showConfirm === false) ? obj.showConfirm : true,
            confirmButtonText: obj.confirmButton,
            cancelButtonText: obj.confirmCancel,
            showCloseButton: true,
            allowEnterKey: false,
            reverseButtons: (obj.reverseButton || obj.reverseButton == false) ? obj.reverseButton : true
        })
    },
    async inputPopup(obj) {
        return await swal.fire({
            confirmButtonText: obj.confirmButton,
            cancelButtonText: obj.confirmCancel,
            customClass: obj.customClass ? obj.customClass : '',
            input: obj.input,
            inputOptions: obj.inputOptions ? obj.inputOptions : {},
            inputLabel: obj.label,
            inputPlaceholder: obj.placeholder,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'This field is required.'
                }
            }
        })
    },
    alertPopupClose() {
        swal.close();
    }
}
export default functions;
